import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import './LocalizationManager.scss';
import HeaderMinimal from "../../components/HeaderMinimal";
import Footer from "../../components/Footer";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const LocalizationManager = () => {
    const array = [
        {id: '1', vacancy: 'Localization Manager', date:'31 May 2024', exp: '3+ years', archive:'23 March 2023', active: true  },
    ];
    const hasActive = array.filter(item => item.active).length > 0;
    const [vacArray, setVacArray] = useState(array)

    const handleOpenClose = (value) => {
        const item = vacArray[0];
        setVacArray([{...item, ...{active: value}}])
        //console.log(value)
    }

    return (
        <>
        <HeaderMinimal/>
            <div className="vacancy-wrapper">
                <div className={'back' + (hasActive ? "" : " hidden")}>
                    <Link to="/">← BACK</Link>
                </div>
               {/* <div className="stateVacancy">
                    <p className={vacArray[0].active ? "active" : ""} onClick={() => handleOpenClose(true)}>Открыта</p>
                    <p className={!vacArray[0].active ? "active" : ""} onClick={() => handleOpenClose(false)}>Закрыта</p>
                </div>*/}                

                <div className="vacancy-elements" style={{color: 'white'}}>
                    <div className="h1-block">
                        <p>Localization Manager</p>
                        <span>Vacancy published on 31 May 2024</span><br/>
                        <span>Experience 3+ years</span>
                    </div>

                    <div className="respons">
                    <div className="response_h2">ABOUT THE ROLE</div>
                        <span>As Localization Manager, you’ll be instrumental in ensuring that our racing games are globally ready for release on various platforms and markets.  Your responsibilities will include overseeing the localization process, collaborating closely with internal and external teams, and guaranteeing that the localized game provides an exceptional experience for all players, regardless of language. This encompasses internationalization, text database management, best practice evangelism, and technical support for internal and external translation and quality assurance teams.  We’re seeking a diligent, highly organized individual who is meticulous in their approach. As a self-starter with project management expertise, you’ll have a deep understanding of linguistic complexities and a passion for quality. You’ll thrive in a collaborative environment, building strong relationships with multiple stakeholders to present the game in its best light.</span>
                        <span>We’re looking for a detail-oriented and meticulous professional who excels at managing projects from start to finish. With a strong foundation in  project management and linguistic nuances, you’ll bring a keen eye for  quality and a passion for delivering exceptional results. You’ll flourish  in a team environment, fostering strong connections with colleagues and  stakeholders to drive success. What’s more, your motivation will come from the desire to streamline and elevate our localization processes, driving  excellence throughout the organization.</span>
                    </div>

                    <div className="respons">
                    <div className="response_h2">RESPONSIBILITIES</div>
                        <ul>
                            <li><span>Develop and execute a localization roadmap, drive continuous operational improvements, and champion internationalization, culturalization, and localization efforts to ensure high-quality and reliable results.</span></li>
                            <li><span>Oversee the translation process, handle and triage questions between multiple stakeholders (translators, LQA testers,  development team, etc), and create customized glossaries and reference materials for external multilingual language teams.</span></li>
                            <li><span>Refine existing pipelines for localization tools, streamline workflows, develop middleware solutions, and provide clarity on  cultural and technical localization issues to ensure early detection of potential problems.</span></li>                            
                        </ul>
                    </div>
                    <div className="respons">
                        <div className="response_h2">QUALIFICATIONS</div>
                        <ul>
                            <li><span>Minimum 3+ years of experience leading localization efforts in the gaming industry or similar media production environments</span></li>
                            <li><span>Solid foundation in game development practices and proficiency with project management tools</span></li>
                            <li><span>Exceptional communication skills, with fluency in written and spoken English</span></li>                            

                        </ul>
                    </div>

                    <div className="respons">
                    <div className="response_h2">EXTRA POINTS</div>
                        <span>Strong grasp of racing games terminology, with a specific focus on drift racing knowledge.</span>                        
                    </div>

                    <div className="respons">
                        <div className="response_h2">WHAT WE OFFER</div>
                        <ul>
                            <li><span>Flexible Working Hours.</span></li>                            
                            <li><span>Remote Work Flexibility.</span></li>
                            <li><span>Training and Skill Development Opportunities.</span></li>
                        </ul>
                    </div>
                    <div className="respons">
                        <div className="response_h2">CONTACT US</div>
                        <ul>
                            <span>info@carx-europe.com</span>
                        </ul>
                    </div>


                    {/*<div className="respons">
                        <div className="response_h2">APPLICATION FORM</div>
                        <Form  className="contact-form vacancy">
                            <Form.Group>
                                <Form.Control type="text" placeholder="Name"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Control type="text" placeholder="Email"/>
                            </Form.Group>
                            <Form.Group>
                            <textarea className="form-control"
                                        name="message"
                                        rows="1"
                                        placeholder="Message"
                                        autoComplete="on"
                                        id="messages"
                                        style={{resize: 'none'}}
                            ></textarea>
                            </Form.Group>
                            <div className="auto_block">
                                <InputGroup>
                                    <InputGroup.Checkbox aria-label="" required />
                                    <p>I confirm I am 18 years of age or older</p>
                                </InputGroup>
                                <InputGroup>
                                    <InputGroup.Checkbox aria-label="" required />
                                    <p>I have read and agree to the <Link >Privacy Policy</Link></p>
                                </InputGroup>
                            </div>

                            <div className="btn-wrapper">
                                <button className="btn-contact" variant="secondary" type="submit" form="recovery">Submit Application</button>
                            </div>
                        </Form>
                    </div>*/}

                </div>
            </div>
            <Footer/>
        </>
    )
}

export default LocalizationManager;