import React, {useState} from "react";
import './TabsPlatform.scss';

const TabsPlatform = () => {

    const ArraySteam = [
        {id: 1, g_logo: './game_logo/street.svg', img: './games/street.jpg'},
        {id: 2, g_logo: './game_logo/dro.svg', img: './games/dro.jpg'},
        {id: 3, g_logo: './game_logo/dr2.svg', img: './games/dr2.jpg'},
        {id: 4, g_logo: './game_logo/rally.svg', img: './games/rally.jpg'}
    ];
    const platforms = [
        {id: 1, img: './platforms/steam.svg'},
        {id: 2, img: './platforms/xbox.svg'},
        {id: 3, img: './platforms/ps.svg'},
        {id: 4, img: './platforms/apple.svg'},
        {id: 5, img: './platforms/google.svg'},
        {id: 6, img: './platforms/huawei.svg'}
    ];

    const [ToggleState, setToggleState] = useState('0');
    const toggleTab = (index) => {
        setToggleState(index);
    };
    const getActiveClass = (index, className) =>
        ToggleState === index ? className : "";


    //const activeID = ArraySteam.map(item => item.id).toString();
    //const isAVA2 = platforms.map(item => item.id).toString();
   // const activeID = array.filter(item => item.active).length > 0;

    console.log()

    return (
        <div className="container">
            <div className="platform-wrapper">
                <div className="platform_icon">
                    {platforms.map(item => (
                        <span key={item.id}
                              /*className={`${getActiveClass(item.id, "active")}`}
                              onClick={() => toggleTab(item.id)}*/>
                       <img src={item.img} alt=""/>
                   </span>
                    ))}
                </div>
            </div>

            <div className="widget-wrapper">
                <div className="widget-subwrapper">
                   <div className="img_block_default">
                       <img src="./games/street.png" alt=""/>
                       <img src="./games/dro.png" alt=""/>
                       <img src="./games/dr2.png" alt=""/>
                       <img src="./games/rally.png" alt=""/>
                   </div>
                    {/*{ArraySteam.map(item => (
                    <div key={item.id} className="widget-block" className={`widget-block ${getActiveClass(item.id, "active")}`}>
                        <div className="picture"><img src={item.img} alt=""/></div>
                        <div className="iso-block">
                            <span><img src={item.g_logo} alt=""/></span>
                        </div>
                    </div>
                    ))}*/}
                </div>
            </div>
        </div>
    );
};

export default TabsPlatform;