import { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import classes from '../Career/Career.module.scss';
import { HeaderMinimal } from '../../components/HeaderMinimal/HeaderMinimal';
import { InfoModal } from "../../components/InfoModal";

const Career = () => {
  const { id, documentId } = useParams();

  const [careerDetail, setCareerDetail] = useState<any | null>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    const fetchCareerDetail = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/careers/${documentId}`);

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const res = await response.json();
        setCareerDetail(res.data);
      } catch (error) {
        console.error('Fetch error: ', error);
        setModalOpen(true);
      }
    };

    fetchCareerDetail();
  }, [documentId]);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <HeaderMinimal />
      <div className={classes.document_container}>
        <div className={classes.career_top}>
          <div className={classes.back}>
            <Link to="/">← BACK</Link>
          </div>
        </div>
        <div className={classes.career_wrapper}>
          <h1 className={classes.careers_aboutTitle}>{careerDetail?.title}</h1>

          <p className={classes.careerItemText}>{careerDetail?.published}</p >
          <p className={classes.careerItemText}>{careerDetail?.experience}</p >
          <p className={classes.careerItemTextTitle}>{careerDetail?.subtitle}</p>

          {careerDetail?.about && (
            <>
              <h2 className={classes.careers_aboutTitleSecond}>ABOUT THE ROLE</h2>
              {/* <p className={classes.careers_aboutTextSecond}>{careerDetail.about}</p> */}
              <p>{careerDetail?.about.split('*').map((item: string, index: number) => (
                <span key={index}>{item}<br /></span>
              ))}</p>
            </>
          )}

          {careerDetail?.responsibilities && (
            <>
              <h2 className={classes.careers_aboutTitleSecond}>RESPONSIBILITIES</h2>
              {/* <p className={classes.careers_aboutTextSecond}>{careerDetail?.responsibilities}</p> */}
              <p>{careerDetail?.responsibilities.split('*').map((item: string, index: number) => (
                <span key={index}>{item}<br /></span>
              ))}</p>
            </>
          )}
          {careerDetail?.qualifications && (
            <>
              <h2 className={classes.careers_aboutTitleSecond}>QUALIFICATIONS</h2>
              {/* <p className={classes.careers_aboutTextSecond}>{careerDetail?.qualifications}</p> */}
              <p>{careerDetail?.qualifications.split('*').map((item: string, index: number) => (
                <span key={index}>{item}<br /></span>
              ))}</p>
            </>
          )}
          {careerDetail?.extra && (
            <>
              <h2 className={classes.careers_aboutTitleSecond}>EXTRA POINTS</h2>
              {/* <p className={classes.careers_aboutTextSecond}>{careerDetail?.extra}</p> */}
              <p>{careerDetail?.extra.split('*').map((item: string, index: number) => (
                <span key={index}>{item}<br /></span>
              ))}</p>
            </>
          )}
          {careerDetail?.offer && (
            <>
              <h2 className={classes.careers_aboutTitleSecond}>WHAT WE OFFER</h2>
              {/* <p className={classes.careers_aboutTextSecond}>{careerDetail?.offer}</p> */}
              <p>{careerDetail?.offer.split('*').map((item: string, index: number) => (
                <span key={index}>{item}<br /></span>
              ))}</p>
            </>
          )}

          <h2 className={classes.careers_aboutTitleSecond}>CONTACT US</h2>
          <a className={classes.careers_email} href="mailto:info@carx-europe.com">info@carx-europe.com</a>
        </div>
        <InfoModal isOpen={modalOpen} onClose={handleCloseModal} />
      </div>
    </>
  )
}

export default Career;
