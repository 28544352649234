// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_footer__PxxIj {
  min-height: 105px;
  margin-bottom: 152px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .Footer_footer__PxxIj {
    flex-direction: column;
    margin-bottom: 52px;
  }
}

.Footer_footer_img__7NB\\+p {
  max-width: 220px;
}
@media (max-width: 1024px) {
  .Footer_footer_img__7NB\\+p {
    margin-bottom: 34px;
  }
}

.Footer_footer_infoContainer__Bs51G {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
}
@media (max-width: 1430px) {
  .Footer_footer_infoContainer__Bs51G {
    max-width: 240px;
  }
}
@media (max-width: 1024px) {
  .Footer_footer_infoContainer__Bs51G {
    margin-bottom: 34px;
  }
}

.Footer_footer_policyContainer__6GUap {
  display: flex;
  flex-direction: column;
  max-width: 160px;
}
@media (max-width: 1430px) {
  .Footer_footer_policyContainer__6GUap {
    max-width: 130px;
  }
}

.Footer_footer_info__1\\+z6T {
  color: var(--text-color-white);
  font: var(--font-size-35)/1.5 Onest-Regular;
  letter-spacing: -0.05em;
  opacity: 0.5;
  text-align: center;
}

.Footer_footer_policy__CSUgx {
  color: var(--text-color-white);
  font: var(--font-size-35)/1.5 Onest-Regular;
  letter-spacing: -0.05em;
  opacity: 0.5;
  text-align: center;
}
.Footer_footer_policy__CSUgx:hover {
  cursor: pointer;
  color: var(--text-color-yellow);
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,oBAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AACF;AACE;EATF;IAUI,sBAAA;IACA,mBAAA;EAEF;AACF;;AACA;EACE,gBAAA;AAEF;AAAE;EAHF;IAII,mBAAA;EAGF;AACF;;AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;AAGF;AADE;EANF;IAOI,gBAAA;EAIF;AACF;AAFE;EAVF;IAWI,mBAAA;EAKF;AACF;;AAFA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AAKF;AAHE;EALF;IAMI,gBAAA;EAMF;AACF;;AAHA;EACE,8BAAA;EACA,2CAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;AAMF;;AAHA;EACE,8BAAA;EACA,2CAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;AAMF;AAJE;EACE,eAAA;EACA,+BAAA;EACA,UAAA;AAMJ","sourcesContent":[".footer {\n  min-height: 105px;\n  margin-bottom: 152px;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n\n  @media (max-width: 1024px) {\n    flex-direction: column;\n    margin-bottom: 52px;\n  }\n}\n\n.footer_img {\n  max-width: 220px;\n\n  @media (max-width: 1024px) {\n    margin-bottom: 34px;\n  }\n}\n\n.footer_infoContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  max-width: 300px;\n\n  @media (max-width: 1430px) {\n    max-width: 240px;\n  }\n\n  @media (max-width: 1024px) {\n    margin-bottom: 34px;\n  }\n}\n\n.footer_policyContainer {\n  display: flex;\n  flex-direction: column;\n  max-width: 160px;\n\n  @media (max-width: 1430px) {\n    max-width: 130px;\n  }\n}\n\n.footer_info {\n  color: var(--text-color-white);\n  font: var(--font-size-35)/1.5 Onest-Regular;\n  letter-spacing: -0.05em;\n  opacity: .5;\n  text-align: center;\n}\n\n.footer_policy {\n  color: var(--text-color-white);\n  font: var(--font-size-35)/1.5 Onest-Regular;\n  letter-spacing: -0.05em;\n  opacity: .5;\n  text-align: center;\n\n  &:hover {\n    cursor: pointer;\n    color: var(--text-color-yellow);\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `Footer_footer__PxxIj`,
	"footer_img": `Footer_footer_img__7NB+p`,
	"footer_infoContainer": `Footer_footer_infoContainer__Bs51G`,
	"footer_policyContainer": `Footer_footer_policyContainer__6GUap`,
	"footer_info": `Footer_footer_info__1+z6T`,
	"footer_policy": `Footer_footer_policy__CSUgx`
};
export default ___CSS_LOADER_EXPORT___;
