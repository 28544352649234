import { GamesProps } from './Games.props';
import { GamesList } from '../../utils/constants';
import classes from './Games.module.scss';

export const Games = (props: GamesProps) => {

    return (
        <section className={classes.games} id="games">
            <div className={classes.games_titleWrapper}>
                <h2 className={classes.games_title}>OUR <span className={classes.games_titleSpan}>GAMES</span></h2>
            </div>
            <p className={`${classes.games_text} ${classes.text_shine}`}>CarX games aren’t just about speed - they're about mastering control, perfecting your skills, and feeling the true essence of motorsport
            </p>
            <div className={classes.games_wrapper}>
                <ul className={classes.games_container}>
                    {GamesList.map(item => (
                        <li
                            key={item.id}
                            className={classes.games_card}
                            style={{ backgroundImage: `url(${item.img})` }}>
                            <div className={classes.games_linkWrapper}>
                                {item.link0 && item.imgLink0 && (
                                    <a href={item.link0} className={classes.games_link} target="_blank" rel="noopener noreferrer">
                                        <img src={item.imgLink0} alt="logo" className={classes.gamesLinlLogo} />
                                    </a>
                                )}
                                {item.link1 && item.imgLink1 && (
                                    <a href={item.link1} className={classes.games_link} target="_blank" rel="noopener noreferrer">
                                        <img src={item.imgLink1} alt="logo" className={classes.gamesLinlLogo} />
                                    </a>
                                )}
                                {item.link2 && item.imgLink2 && (
                                    <a href={item.link2} className={classes.games_link} target="_blank" rel="noopener noreferrer">
                                        <img src={item.imgLink2} alt="logo" className={classes.gamesLinlLogo} />
                                    </a>
                                )}
                                {item.link1 && item.imgLink3 && (
                                    <a href={item.link1} className={classes.games_link} target="_blank" rel="noopener noreferrer">
                                        <img src={item.imgLink3} alt="logo" className={classes.gamesLinlLogo} />
                                    </a>
                                )}
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};