import React from "react";


const Notice = (props) => {

    return (
        <>
            <div className="notice-inner">
                <p>Last modified: March 7, 2023</p><br />
                <p>This Cookie Notice applies to the CarX Technologies EU (referred to as “CARX”, “we”, “us”, or “our”) websites. It supplements our Privacy Policy [link to Privacy Policy].
                Any questions or concerns relating to the use of cookies by our website and the processing of your personal data may be directed to: privacy@carx-europe. com.</p>

                    <br /><h1>1. What is a cookie?</h1>
                <p>A cookie is a small file that is stored on a user’s computer for record-keeping purposes. Cookies themselves do not identify you, as they are only designed to identify the device and browser when you visit the website again. In this case, only the cookies already available on your device will be used.<br />
                CARX uses cookies and similar technologies to collect information about the use of our websites in order to make them function effectively and also to improve your experience when you browse our websites or play web versions of our games.</p>

                    <br /><h1>2. Types of cookies that we use</h1>
                    <p>Cookies on our websites belong to one of the following categories:<br />
                • “Strictly Necessary” cookies are necessary for our websites to function and cannot be switched off in our systems. They are set to respond to actions taken by you to make a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser or device settings to block or alert you about these cookies, but some parts of our websites may no longer be functional.<br />
                • “Performance” cookies allow us to count visits and traffic sources so we can measure and improve the performance of our websites. All information these cookies collect is aggregated by third-party providers or immediately by us. We will only set these types of cookies where you have provided us with your consent to do so. If you block these cookies, we will not know when you have visited our websites, and will not be able to remember your preferences.<br />
                • “Functional” cookies enable our websites to provide enhanced functionality and personalisation. They may be set by us or by third-party providers whose services we have added to our pages. We will only set these types of cookies where you have provided us with your consent to do so. If you do not allow these cookies then some or all of these services may not function properly.<br />
                • “Targeting” cookies may be set through our websites by our partners. They may be used to build a profile of your interests and show you relevant adverts on other websites. They do not directly store your personal details but do uniquely identify your browser or your device. If you do not allow these cookies, you will experience less personalised advertising.<br />
                • “Social Media” cookies may be set by social media services (e. g., YouTube) that we have added to our websites to enable you to share our content with your friends and networks. These cookies are capable of tracking your browser across other websites and building up a profile of your interests. This may impact the content and messages you see on other websites you visit.<br />
                To view a list of the cookies that are used by our websites, click on Cookies Settings at the bottom of this page.</p>

                        <br /><h1>3. Third-party cookies</h1>
                <p>Cookies may be either first-party cookies or third-party cookies. First-party cookies are cookies set directly by us to your device. Third-party cookies are cookies set by a third-party provider on our behalf.<br />
                When you visit our websites, third-party organisations may place cookies on your browser. These cookies may inform third-party organisations about your visit to our websites, which may be used by those organisations to deliver targeted messages and advertising to you. We do not control third-party cookies and cannot access them due to the way that cookies work, as cookies can only be accessed by the party who originally set them. The details of third-party cookies that may be set through our websites are available through the Cookies Settings link at the bottom of this page.<br />
                Such cookies are subject to third-party organisations’ own cookie policies. Please read their privacy policies to ensure you are comfortable with how they use cookies.</p><br />

                    <table className="table">
                        <tbody>
                        <tr>
                            <td>Third-Party Cookies</td>
                            <td>Third-Party Privacy Policies</td>
                        </tr>
                        <tr>
                            <td> Facebook</td>
                            <td>https://www.facebook.com/policy.php</td>
                        </tr>
                        <tr>
                            <td>Google</td>
                            <td>https://policies.google.com/privacy?hl=en</td>
                        </tr>
                        <tr>
                            <td>Yandex</td>
                            <td>https://yandex.ru/legal/confidential/?lang=en</td>
                        </tr>
                        </tbody>
                    </table>
                <p>To learn more about opting out of Google Analytics which uses cookies as well as your IP address, you can visit this website.<br />
                To learn more about opting out of Yandex. Metrica, you can visit this website.</p>
                <br /><h1>4. How long will cookies remain on my device?</h1>
                <p>Please check your Сookies Settings for details.</p>

                    <br /><h1>5. Links to other websites</h1>
                <p>Our websites may contain links to other websites that are not owned or controlled by CARX. Please be aware that CARX is not responsible for the privacy or security practices of such other websites.</p>

                    <br /><h1>6. How to manage cookies using your browser</h1>
                <p>You can control and manage cookies in various ways. Insofar as cookies we use are not essential for the provision of our websites, we will ask you to consent to our use of cookies when you first visit our websites. You do not have to accept all of the cookies used by our websites. Consent can be provided or revoked by clicking on Cookies Settings at the bottom of this page. Please keep in mind that if you reject cookies, you may still use our websites, but your ability to use some areas of our websites will be limited and you may need to re-enter your personal data when you register.
                You may also be able to change your browser settings to manage and control cookies. Your browser or device may be configured to allow you to refuse or delete cookies or to be notified when a cookie is stored on your device. To change your web browser or device settings for cookies, you can follow the instructions in the Help section of your web browser. You can also find detailed information on how to do so at https://www. aboutcookies. org/how-to-manage-and-delete-cookies. To learn more about behavioural advertising and how to control your interest-based advertising preference, you can review the following resources:<br />
                • Network Advertising Alliance<br />
                • Digital Advertising Alliance<br />
                    • Your Online Choices<br /></p>

                    <br /><h1>7. Changes to this Cookie Notice</h1>
                <p>We many modify or amend this Cookie Notice from time to time at our discretion. When we make changes, we will update the “Last modified” date at the top of this page.<br />
                    We advise you to periodically review this Cookie Notice to be informed about how we use cookies.</p>

            </div>
        </>
    )
}

export default Notice;