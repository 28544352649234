import React from "react";
import './Header.scss';
import {Link} from "react-router-dom";


const HeaderMinimal = () => {

    return (
        <>
            <header>
                <Link to='/'>
                    <div className="logo center"></div>
                </Link>
            </header>
            <div className="clearTop"></div>
        </>


    )
}

export default HeaderMinimal;
