import React from "react";
import './Freedom.scss';

const Freedom = (props) => {

    return (
        <>
            <div className="freedom-wrapper">
            <div className="freedom_block">
                <div className="freedom_h1">
                    <span>FREEDOM TO CREATE</span>
                    <p>We are constantly cooking new games. Join us to become the owners of the new projects <br/>with freedom to innovate and bring new ideas into life</p>
                </div>
                    <div className="freedom__element">
                        <img src="./car/lambo.png" alt=""/>
                        <div className="substrate"></div>
                    </div>
                </div>
            </div>
            <div className="mobile-wrapper">
                <div className="mobile_h1">
                    <span>MOBILE & CONSOLE</span>
                    <p>Aspiring Irish game developers can stay in Ireland and get<br/> the experience of working on world-class games for all platforms</p>
                </div>
                <div className="freedom__element_charger">
                    <img src="./car/charger.png" alt=""/>
                </div>
            </div>


        </>
    )
}

export default Freedom;