import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AppProps } from './App.props';
import { Wrapper } from '../../components';
import classes from './App.module.scss';
import PrivacyPolicy from '../../pages/PrivacyPolicy/PrivacyPolicy';
import EULA from '../../pages/EULA/EULA';
import Career from '../../pages/Career/Career';
import { CookiesProvider } from 'react-cookie';

export const App = (props: AppProps) => {

    return (
        <CookiesProvider>
            <Router>
                <div className={classes.app}>
                    <Routes>
                        <Route path="/" element={<Wrapper />} />
                        <Route path={"carx-technologies-privacy-policy"} element={<PrivacyPolicy />} />
                        <Route path={"carx-technologies-eula"} element={<EULA />} />
                        <Route path={"careers/:documentId"} element={<Career />} />
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </div>
            </Router>
        </CookiesProvider>
    );
}