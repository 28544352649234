import { ContactProps } from './Contact.props';
import classes from './Contact.module.scss';

export const Contact = (props: ContactProps) => {

    return (
        <section className={classes.contact} id="contact">
            <h2 className={classes.contact_title}>LET’S CONNECT</h2>
            <a className={classes.contact_email} href="mailto:info@carx-europe.com">info@carx-europe.com</a>
        </section>
    );
};