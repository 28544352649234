import React from "react";
import './Address.scss';

const Address = (props) => {

    return (
        <>
            <div className="address-wrapper" id="contact">
                <div className="address_h1">
                    <span>LOCATION</span>
                    <p>The office is next to the Spire. Sail around the city on any bus or Luas. With Connolly <br/>
                        station within 10 minutes walk you can come and go as you please.</p>
                </div>
                <div className="map">
                        <iframe width="80%" height="800" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Prosperity%20Chambers,%205-7%20O%E2%80%99Connell%20Street%20Upper,%20Dublin%201,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=18&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                        </iframe>

                </div>
                <p className="address_text">Prosperity Chambers, 5-7 O’Connell Street Upper, Dublin 1, Ireland</p>
            </div>

        </>
    )
}

export default Address;