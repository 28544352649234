import { LocationProps } from './Location.props';
import { PlaceList } from '../../utils/constants';
import { Contact } from "../Contact";
import { Footer } from "../Footer";
import classes from './Location.module.scss';

export const Location = (props: LocationProps) => {

    return (
        <section className={classes.location}>
            <h2 className={classes.location_title}>LOCATION</h2>
            <p className={classes.location_text}>The office is next to the Spire. Sail around the city on any bus or Luas. With Connolly station within 10 minutes walk you can come and go as you please
            </p>
            <div className={classes.location_containerPlace}>
                <div className={classes.location_wrapperMap}>
                    <iframe className={classes.location_map}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2381.61044788757!2d-6.2625674223932295!3d53.350229174431874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48670e843b2e3f51%3A0xf935027e98913bc3!2zUHJvc3Blcml0eSBDaGFtYmVycywgTydDb25uZWxsIFN0cmVldCBVcHBlciwgTm9ydGggQ2l0eSwgRHVibGluIDEsIEQwMSBIUjA0LCDQmNGA0LvQsNC90LTQuNGP!5e0!3m2!1sen!2sen!4v1734681213250!5m2!1sen!2sen"
                        style={{ border: '0', width: '100%', height: '100%' }} allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                </div>
                {/* <div className={classes.location_wrapperAddress}> */}
                <p className={classes.location_address}>Prosperity Chambers, 5-7 O’Connell Street Upper, Dublin 1, Ireland</p>
                {/* <div className={classes.location_wrapperPlace}> */}
                {/* {PlaceList.map(item => (
                            <div
                                key={item.id}
                                className={classes.place_item}
                                style={{ backgroundImage: `url(${item.img})` }}>
                            </div>
                        ))} */}
                {/* </div> */}
                {/* </div> */}
            </div>
            <Contact />
            <Footer />
        </section>
    );
};