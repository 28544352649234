import { StrengthsProps } from './Strengths.props';
import { StrengthsList } from '../../utils/constants';
import classes from './Strengths.module.scss';

export const Strengths = (props: StrengthsProps) => {

    const colors = ['#DFF749', '#4662D0'];

    return (
        <section className={classes.strengths}>
            <div className={classes.strengths_titleWrapper}>
                <h2 className={classes.strengths_title}>OUR <span className={classes.strengths_titleSpan}>STRENGTHS</span></h2>
            </div>
            <div className={classes.strengths_container}>
                {StrengthsList.map((item, index) => (
                    <li
                        key={item.id}
                        className={classes.strengths_card}
                        style={{ backgroundImage: `url(${item.img})` }}>
                        <h5
                            className={classes.strengths_cardTitle}
                            style={{ color: colors[index % colors.length] }}
                        >
                            {item.title}
                        </h5>
                        <p className={classes.strengths_cardText}>{item.text}</p>
                    </li>
                ))}
            </div>
        </section>
    );
};