import { ModalAcceptProps } from './ModalAccept.props';
import classes from './ModalAccept.module.scss';
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";


export const ModalAccept = (props: ModalAcceptProps) => {
  const [showConsent, setShowConsent] = useState(true);
  const [cookies, setCookie] = useCookies(['localConsent']);

  useEffect(() => {
    if (cookies.localConsent) {
      setShowConsent(false);
    }
  }, [cookies]);

  const acceptCookie = () => {
    setShowConsent(false);
    setCookie('localConsent', true, { path: '/', maxAge: 365 * 24 * 60 * 60 });
  };
  if (!showConsent) {
    return null;
  }

  return (
    <div className={classes.modalOverlay}>
      <div className={`${classes.container} ${classes.modalWindow}`}>
        <h2 className={classes.title}>Cookie</h2>
        <p className={classes.text}>
          We use cookies to save your preferences and analyze site traffic
        </p>
        <a
          type="button"
          className={classes.btnLink}
          href='/carx-technologies-privacy-policy'
          target="_blank"
        >
          Learn more
        </a>
        <button
          type="button"
          className={classes.btnAccept}
          onClick={acceptCookie}
        >
          Accept
        </button>
      </div>
    </div>
  );
};

