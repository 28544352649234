import { HeaderProps } from './Header.props';
import classes from './Header.module.scss';
import { useEffect, useState } from "react";
import menu from '../../images/btn/burger_menu.png';
import close from '../../images/btn/burger_close.png';
import logo from '../../images/logo/logo.svg';

export const Header = (props: HeaderProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && isMenuOpen) {
            closeMenu();
        }
    };

    const handleResize = () => {
        if (window.innerWidth > 1024 && isMenuOpen) {
            closeMenu();
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('resize', handleResize);
        };
    }, [isMenuOpen]);

    const handleLinkClick = () => {
        if (isMenuOpen) {
            closeMenu();
        }
    };

    return (
        <header className={classes.header}>
            <img
                className={classes.header_logo}
                src={logo}
                alt='Логотип'
            />
            <nav className={`${classes.header_list} ${isMenuOpen ? classes.open : ''}`}>
                <a className={classes.header_link} href="#about" onClick={handleLinkClick}>about us</a>
                {/* из-за скрытия Games, потом открыть btn */}
                {/* <a className={classes.header_link} href="#games" onClick={handleLinkClick}>games</a> */}
                <a className={classes.header_link} href="#careers" onClick={handleLinkClick}>careers</a>
                <a className={classes.header_link} href="#contact" onClick={handleLinkClick}>contact us</a>
            </nav>
            <button className={classes.burger} onClick={toggleMenu}>
                <img
                    className={classes.header_menu}
                    src={isMenuOpen ? close : menu}
                    alt={isMenuOpen ? 'Закрыть меню' : 'Открыть меню'}
                />
            </button>
            {isMenuOpen && <div className={classes.overlay} onClick={closeMenu} />}
        </header>
    );
};