import 'bootstrap/scss/bootstrap.scss';
import React from "react";

import './reset.scss';
import './App.scss';
import './FontScheme.scss';
import './components/cookie/Cookie.scss';
import Footer from './components/Footer';
import TopSection from './components/TopSection';
import Separator from './components/Separator';
import Mission from './components/Mission';
import Ourgames from './components/Ourgames';
import Office from "./components/Office";
import Freedom from "./components/Freedom";
import Job from "./components/Job";
import Address from "./components/Address";
import Contact from "./components/Contact";
import Header from "./components/Header";
import Cookie from "./components/cookie/Cookie"; /*не убирать с App.js*/


const App = (props) => {

  return (
      <>
          <Header/>
          <TopSection/>
          <Mission/>
          <Separator/>
          <Ourgames/>
          <Office/>
          <Freedom/>
          <Job/>
          <Address/>
          <Separator/>
          <Contact/>
          <Footer />
          {/*<Cookie/>*/}

      </>
  )
};

export default App;

