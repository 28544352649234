import { Link } from 'react-router-dom';
import classes from './PrivacyPolicy.module.scss';
import { HeaderMinimal } from '../../components/HeaderMinimal/HeaderMinimal';
import { POLICY_URL } from '../../utils/constants';
import { EULA_URL } from '../../utils/constants';

const PrivacyPolicy = () => {

  return (
    <>
      <HeaderMinimal />
      <div className={classes.document_container}>
        <div className={classes.privacy_top}>
          <div className={classes.back}>
            <Link to="/">← BACK</Link>
          </div>
          <div className={classes.privacy_h1}>Privacy Policy</div>
        </div>
        <div className={classes.privacy_wrapper}>
          <div className={classes.privacy_element}>
            <p className={classes.update_date}>Revised December 20, 2024</p>
            <div className={classes.btn_download}>
              <a href={POLICY_URL} rel="noreferrer"
                target="_blank" >Download PDF</a>
            </div>
            <p>
              This Privacy Policy (“<b>Policy</b>”) describes how private limited company
              (Ltd) “CarX Technologies EU” (“<b>CarX Technologies</b>”, “<b>we</b>”)
              processes personal data. By expressing your consent in the forms
              presented in our Games, or by using our Website (collectively “
              <b>Services</b>”), you or your representative (“<b>User</b>”, “
              <b>you</b>”) accept the Policy.
            </p>
            <p><br />
              <b>Acceptance of the Policy with respect to minors</b>
              <br />
              To accept the Policy, you must reach the age of sufficient legal
              capacity under applicable legislation (the laws of the country of your
              residence) (particularly, for residents of the Russian Federation it is
              18 years, for residents of EU – 13 to 16 years, depending on the country
              of your residence within EU, in the USA – 13 to 16 years, depending on
              the state of residence within the USA).
              <br />
              If you are under the age of legal capacity, please ask your parents or
              other legal representatives for assistance before using our Games. By
              continuing to use our Games, you certify that you have either reached
              the age of legal capacity to accept the Policy, or your parent/legal
              representative has reviewed and agreed to the terms of the Policy on
              your behalf.
            </p>
            <p>
              If you, as a parent or other legal representative, learn that your child
              has accepted the Policy without your consent, please contact us (for
              example, via e-mail at <a href="mailto:support@carx-europe.com"> support@carx-europe.com</a> or
              other means indicated on the Website). We will promptly erase
              corresponding data and inform our partners about it.
            </p>
            <p>
              We recommend that you monitor your children’s game sessions and web
              activity, as well as study parental control options that can be provided
              by us and/or our partners.
            </p>
            <p><br />
              <b>Applicable legislation</b>
              <br />
              CarX Technologies treats collection and use of its players’ and Service
              users&apos; data responsibly. Considering the international nature of distribution of its Services, the Policy was developed in such a
              manner as to comply with the requirements of General Data Protection Regulation (European Union)
              2016/679 (hereinafter “<b>GDPR</b>”), as well as with the requirements of the
              State of California (USA) and the US federal laws, as those setting a
              high standard for protection and responsible processing of personal
              data. In the Policy we also take into account Privacy Shield framework
              agreements, to which Switzerland and the European Union are parties.
            </p>
            <p>We confirm that our internal processes are aligned with the Policy.</p>
            <p><br />
              <b>Application of the Policy</b>
              <br />
              This Policy applies to all CarX Technologies Games worldwide on all
              Platforms without limitation. Please, note, that privacy policies of
              Platforms a Game was downloaded from by a User may establish their own
              rules for users&apos; personal data processing when using the corresponding
              Platform. The Platform is independently responsible for such processes.
            </p>
            <p><br />
              <b>Amendment of the Policy</b> The Policy available at <a
                href={POLICY_URL}
                target="_blank"
                rel="noreferrer"
              >
                https://carx-europe.com/uploads/userfiles/Privacy_Policy_ENG.pdf
              </a> is presented in the most up-to-date version. Over time, CarX Technologies
              may unilaterally amend the Policy, including to comply with the latest
              changes in the applicable law and jurisprudence. All changes come into
              force from the moment they are published on this page, unless a different
              period is indicated in the text of the amendments. CarX Technologies
              will make every effort to organize additional ways to notify Users of
              amendments to the Policy, but we ask you to regularly check for the
              latest version on the Website yourself.
            </p>
            <h2>DEFINITIONS:</h2>
            <ul>
              <li>
                <b>“Contact data”</b> is the data that we collect when a User contacts
                our technical support service of CarX Technologies in order to be able
                to contact User as part of user support provision, as well as when
                using the CarX ID function. Such data includes User data determined
                according to means User exploits in order to contact us with the aim
                to receive user support: email address (including when using the CarX
                ID function), phone number and messenger login (username).
              </li>
              <li>
                <b>“Cookies”</b> are small fragments of data sent by our web server
                and stored on User&apos;s Device. Cookies are stored when you visit the
                Website, and this allows us to make it work effectively: Cookies allow
                us to save your selected settings (e.g., language) and analyse Website
                traffic. You have the right to refuse Cookies that you do not want to
                be used in the Cookie-banner on the Website, however, please, note
                that you cannot disable Cookies necessary for functionality of the
                Website.
              </li>
              <li>
                <b>“Device”</b> is a device from which you use our Games and/or
                Website. Devices include mobile devices, tablets, personal computers,
                consoles, etc.
              </li>
              <li>
                <b>“Device information”</b> is technical information about your
                Device. Device information includes browser data and types of browser
                plug-ins, operating system and operating system version, information
                about the type and manufacturer of a Device, language set for a
                Device, version of Games installed on a Device used.
              </li>
              <li>
                <b>“Financial information”</b> is information about purchases made by
                a User, including purchase of In-Game Objects. Such information
                includes transaction ID (financial transaction number, including
                purchase, recovery, return), transaction amount, billing address,
                transaction date, transaction history (time of initiation of
                processing and completion time), purchase history. Please, note that
                when you make in-game purchases in Games, we do not collect and do not
                have access to your name, contact details and payment information.
                This information is collected and stored by the Platform or payment
                system operator of the Platform which you use to launch Games.
              </li>
              <li>
                <b>“Game”</b> is a game developed and published by CarX Technologies,
                regardless of the Platform on which it is distributed or the Devices
                for which it is developed
              </li>
              <li>
                <b>“IDs”</b> are technical identifiers of a User assigned to them or
                their Device. Such identifiers include User ID assigned by CarX
                Technologies; Platform ID; Device ID assigned by CarX Technologies,
                advertising identifier of a User&apos;s Device (IDFA, GAID, etc.).
              </li>
              <li>
                <b>”License agreement”</b> is an agreement between us and a User, on
                the basis of which User is granted access to our Games and other
                Services. User enters into a License agreement with us by accepting
                its terms upon the first launch of a Game or access of the Website.
                The License agreement is posted here: <a
                  href={EULA_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  https://carx-europe.com/uploads/userfiles/EULA_CarX_ENG.pdf
                </a>
              </li>
              <li>
                <b>“Location information”</b> is general information about User&apos;s
                location, collected when they run the Game. CarX Technologies does not
                collect accurate information about User&apos;s location. However, we may
                collect Device’s IP address, which can be used to determine the
                general location of a User (User&apos;s country of residence).
              </li>
              <li>
                <b>“Platform”</b> is an online store through which the Game is
                provided to the User. The Platforms include, in particular:
                <ul>
                  <li>
                    Stores for mobile versions of the Game: Google Play, App Store,
                    and Huawei App Gallery;
                  </li>
                  <li>Store for PC versions of the Game: Steam;</li>
                  <li>
                    Stores for console versions of the Game: PlayStation Store,
                    Nintendo eShop, Xbox Games Store
                  </li>
                </ul>
                This list is not complete and can be changed from time to time.
              </li>
              <li>
                <b>“User information”</b> is information that a User independently
                creates (publishes and presents) in a Game. User information includes
                User chat history (text messages); User avatar (including his
                real-life photo); nickname in a Game; list of “friends” in a Game;
                data about User&apos;s gaming experience, including statistics and User
                progress within a Game. User information is provided by a User
                voluntarily and becomes available to other users. CarX Technologies is
                not liable for composition and fact of dissemination of personal
                information that a User voluntarily and on his own initiative
                discloses to third parties. We also do not assume that a User will
                post his real-life photo as her avatar.
              </li>
              <li>
                <b>“Website”</b> is a website available at <a href="https://carx-europe.com/" target="_blank"
                  rel="noreferrer"> https://carx-europe.com/</a>
              </li>
            </ul>
            <h2>ABOUT US</h2>
            <p>Car X Technologies EU Ltd</p>
            <p>Reg.no 716662</p>
            <p>
              Legal address: 5-7 O&apos;Connell street upper, Dublin 1, D01 K2W, Ireland
            </p>
            <p>
              E-mail: <a href="mailto:support@carx-europe.com">support@carx-europe.com</a>
            </p>
            <h2>WHAT TYPES OF INFORMATION DO WE COLLECT AND FOR WHAT PURPOSES?</h2>
            <p><br />
              <b>Why do we process your personal data?</b>
              <br />
              Collection and processing of Users&apos; personal data is essential for our
              ability to support Games and Website, as well as their functionality:
              without it we cannot provide user support, display advertisements so
              that some of our Games and their elements remain free for you, restore
              progress in Games when switching Devices, analyze statistics of our
              Games to improve your user experience, correct errors in Games,
              determine the need for changes in our commercial decisions regarding
              Games.
            </p>
            <p>
              We do not use automated processing of personal data to make decisions
              that entail legal consequences for a subject of personal data, or
              otherwise affect her rights and legitimate interests.
            </p>
            <p><br />
              <b>Legal grounds to process your personal data.</b>
              <br />
              In order to process User data, we obtain your consent to processing of
              personal data in accordance with the Policy. Without obtaining such
              consent, unfortunately, we are forced to terminate access to the
              Services or significantly limit functionality of the Services for you.
              At the same time, you always have an opportunity to withdraw your
              consent or request erasure of any information collected by us, even if
              you have given us initial consent to processing of your data (please,
              read the section “What rights do you have with respect to personal
              data?” to learn how to exercise your rights).
            </p>
            <p>Collectively, we rely on the following grounds for data processing:</p>
            <ol>
              <li>
                your explicit informed consent with the Policy to processing,
                expressed through consent forms within the Services;
              </li>
              <li>
                the need to collect data in order for us to fulfill our obligations
                under the contract concluded between you and CarX Technologies
                (License agreement);
              </li>
              <li>
                our legitimate interest, including:
                <ul>
                  <li>
                    market research where we offer our services, analysis of
                    statistics;
                  </li>
                  <li>
                    exercise and protection of our rights and rights of third parties;
                  </li>
                  <li>marketing and promotion of our Services;</li>
                  <li>ensuring security and fraud prevention in the Services.</li>
                </ul>
              </li>
            </ol>
            <text><br />
              <b>What information do we collect and process?</b>
              <br />
              We collect only the following categories of personal data:
              <ul>
                <li>User information;</li>
                <li>Contact data;</li>
                <li>IDs;</li>
                <li>Financial information;</li>
                <li>Location information;</li>
                <li>Device information;</li>
                <li>Cookies.</li>
              </ul>
            </text>
            <p>
              We do not collect any other categories of data and make every effort to
              exclude accidental collection of additional data
            </p>
            <p>
              The following table provides a list of data collected, as well as the
              reasons and purposes for its collection.
            </p>
            <div className={classes.table}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <b>Category</b>
                    </td>
                    <td>
                      <b>Legal basis</b>
                    </td>
                    <td>
                      <b>Purpose(s)</b>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <b>When you use Games</b>
                    </td>
                  </tr>
                  <tr>
                    <td>User information</td>
                    <td>
                      <ul>
                        <li>Consent</li>
                        <li>Agreement (conclusion of the License agreement)</li>
                        <li>
                          Our legitimate interest in market research we offer our Services
                          on, statistics analysis.
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li>
                          Ability to “customize” certain sections of a Game for a User
                          (ability to use an avatar, nickname)
                        </li>
                        <li>
                          Ability to save Game progress and play a Game on other Devices
                        </li>
                        <li>Provision of user support</li>
                        <li>Statistics analysis of the use of our Services.</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>IDs</td>
                    <td>
                      <ul>
                        <li>Consent</li>
                        <li>
                          Our legitimate interest in market research we offer our Services
                          on, statistics analysis.
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li>Providing Users with access to Games</li>
                        <li>Statistics analysis of the use of our Services</li>
                        <li>Marketing and demonstration of advertisements.</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Financial information</td>
                    <td>
                      <ul>
                        <li>Collection and accounting of transactions, reporting</li>
                        <li>Revision of payments, crediting for payment, refunds</li>
                        <li>User support.</li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li>Collection and accounting of transactions, reporting</li>
                        <li>Revision of payments, crediting for payment, refunds</li>
                        <li>User support.</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Location information,
                      <br />
                      <br />
                      Device information
                    </td>
                    <td>
                      <ul>
                        <li>Consent</li>
                        <li>Agreement (conclusion of the License agreement)</li>
                        <li>
                          Our legitimate interest in market research we offer our Services
                          on, statistics analysis.
                        </li>
                      </ul>
                    </td>
                    <td>
                      <ul>
                        <li>Statistics analysis of the use of our Services</li>
                        <li>
                          Ability to save game progress and play a Game on other Devices.
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <b>When you use the Website</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Cookies,
                      <br />
                      <br />
                      Device information,
                      <br />
                      <br />
                      Location information
                    </td>
                    <td>
                      <ul>
                        <li>Consent</li>
                        <li>Agreement (conclusion of the License agreement)</li>
                        <li>
                          Our legitimate interest in market research we offer our Services
                          on, statistics analysis.
                        </li>
                      </ul>
                    </td>
                    <td>Statistics analysis of the use of our Services.</td>
                  </tr>
                  <tr>
                    <td colSpan={3}>
                      <b>When you contact our user support</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Contact data
                    </td>
                    <td>
                      <ul>
                        <li>
                          Consent, including consent expressed by implicative actions
                        </li>
                        <li>Agreement (conclusion of the License agreement)</li>
                        <li>
                          Our legitimate interest in market research we offer our Services
                          on, statistics analysis.
                        </li>
                      </ul>
                    </td>
                    <td>
                      We will collect and use information that you provide to us upon
                      request in order to be able to contact you and provide support: this
                      is necessary to fulfill obligations of CarX Technologies to provide
                      Games to users, as well as to use the CarX ID function.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <h2>IS PERSONAL DATA TRANSFERRED TO THIRD PARTIES?</h2>
            <p>
              We may transfer your data to third parties indicated in this Policy
              within the designated purposes. By accepting the Policy, you confirm
              your consent to such transfer
            </p>
            <p>
              CarX Technologies is engaged in game development, while our partners
              focus on their storage, analytics and demonstration of advertisements.
              Together we aim at providing you with the best user experience in the
              Games.
            </p>
            <p>
              We allow our partners&apos; services to collect the following categories of
              data:
            </p>
            <ul>
              <li>User information;</li>
              <li>IDs;</li>
              <li>Location information;</li>
              <li> Device information;</li>
              <li>Cookies.</li>
            </ul>
            <p>This allows us to:</p>
            <ul>
              <li>
                store User data to provide high-quality support, as well as to restore
                progress, purchases in Games, and implementation of the multiplayer
                function;
              </li>
              <li>analyze and improve our Games;</li>
              <li>show you advertisements, including targeted advertisements.</li>
            </ul>
            <p>
              Below is an up-to-date list of our partners and links to their privacy
              policies:
            </p>
            <div className={classes.table}>
              <table>
                <thead>
                  <tr>
                    <th>Third-party service</th>
                    <th>Link to privacy policy</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Playfab</td>
                    <td>
                      <a
                        href="https://playfab.com/terms/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://playfab.com/terms/
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Flurry</td>
                    <td>
                      <a
                        href="https://www.termsfeed.com/blog/privacy-policy-flurry/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.termsfeed.com/blog/privacy-policy-flurry/
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Unity</td>
                    <td>
                      <a
                        href="https://unity3d.com/legal/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://unity3d.com/legal/privacy-policy
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Yandex Metrics</td>
                    <td>
                      <a
                        href="https://metrica.yandex.com/about/info/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://metrica.yandex.com/about/info/privacy-policy
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Admob</td>
                    <td>
                      <a
                        href="https://www.termsfeed.com/blog/privacy-policy-admob/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.termsfeed.com/blog/privacy-policy-admob/
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Selectel</td>
                    <td>
                      <a
                        href="https://selectel.ru/en/about/documents/personal-data/en.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://selectel.ru/en/about/documents/personal-data/
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Amazon AWS</td>
                    <td>
                      <a
                        href="https://aws.amazon.com/ru/privacy/en.html"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://aws.amazon.com/ru/privacy/
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Google Firebase</td>
                    <td>
                      <a
                        href="https://policies.google.com/privacy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://policies.google.com/privacy
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Facebook</td>
                    <td>
                      <a
                        href="https://www.facebook.com/policy.php"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://www.facebook.com/policy.php
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Microsoft</td>
                    <td>
                      <a
                        href="https://privacy.microsoft.com/en-us/privacystatement"
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://privacy.microsoft.com/en-us/privacystatement
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <p>
              We guarantee that the agreements we conclude with third parties being
              our partners ensure the level of personal data protection not lower than
              the Policy does and comply with the requirements of applicable data
              protection legislation.
            </p>
            <p>
              We represent that we have concluded data processing agreements with all
              partners, which describe relevant technical and organizational measures
              taken by our partners to ensure protection of personal data, as well as
              an obligation not to further transfer personal data to other third
              parties.
            </p>
            <p>
              Third-party services that we use introduce technical and organizational
              measures to ensure secure processing of personal data, as specified in
              their privacy policies.
            </p>
            <h2>HOW DOES TARGETED ADVERTISING WORK IN GAMES?</h2>
            <p>
              We confirm that some of our Games contain advertising, so IDs are
              transferred to our advertising partners in order to implement this
              function. This is the minimum set of data required for our advertising
              partners, and we do not share any personal data with them that would
              allow them to identify a specific person in absence of additional
              information. However, advertising partners may additionally identify you
              based on other data about you available to them (for example, based on
              your gaming experience in another application). Based on this data, our
              partners can personalize advertisements that you can see. Practical
              point of using data is to present an advertiser the opportunity to place
              advertisements specifically for you through a personalized announcement.
              We do not pursue any other purpose when collecting, processing and
              transmitting the said data.
            </p>
            <p><br />
              <b>How to opt out of receiving targeted advertisements</b>
              <br />
              If you do not want to receive targeted advertising, you can opt out of
              our processing of your personal data, however, the functionality of our
              Services available to you will be limited. You can contact us
              at <a href="mailto:support@carx-europe.com"> support@carx-europe.com</a> to
              communicate your desire to opt out of receiving targeted advertisements.
              We will share this information with third parties whose services we use.
              Please, note that if you opt out of receiving targeted advertising, you
              can still receive contextual advertising that is not targeted, which is
              based on the content of the Games you use.
            </p>
            <p>
              Third-party services may contain their own opt-out instruments in
              relation to selling personal data. To learn how third parties whose
              services we use deal with the issue of selling personal data, study
              their respective privacy policies.
            </p>
            <p>
              In addition, your mobile device may allow you to opt out of receiving
              targeted advertising in all applications available on your device. On
              Android devices, you can opt out by enabling the “Opt out of ads
              personalization” option. On iOS devices, demonstration of targeted
              advertising is disabled by default, you can change this in the
              “Tracking” menu in Settings.
            </p>
            <h2>OUR RECEIPT OF PERSONAL DATA FROM THIRD PARTIES</h2>
            <p>
              We may receive personal data from third parties, including from Platform
              and social media.
            </p>
            <p>
              By accepting this Policy, you consent to our processing of personal data
              provided to us by third parties. At the same time, we guarantee that we
              will independently ask a third party --source of personal data -- to
              confirm that it has correctly collected consent to the transfer of data
              to us.
            </p>
            <p>
              However, if you have reasons to believe that we process your personal
              data without your consent to transfer them to us, please, contact us via
              email <a href="mailto:support@carx-europe.com"> support@carx-europe.com</a>.
            </p>
            <h2>LINKS TO THIRD-PARTY SERVICES</h2>
            <p>
              While using our Games, you may see links to third-party websites or
              applications (for example, in advertisements shown in Games). We would
              like to warn you that we are not liable for the services of third
              parties and their data collection processes, and do not control them. By
              clicking on the links, you may share your personal data with third
              parties in accordance with their data processing rules.
            </p>
            <h2>HOW LONG DO WE STORE PERSONAL DATA?</h2>
            <p>
              We do our best to limit the period of data processing to the necessary
              minimum and not store it for longer than it is reasonably necessary.
            </p>
            <p>
              In accordance with the applicable law, we process your personal data
              only while you play Games, as well as for three years from the date of
              your last gaming session and activity in the Game. It is necessary to
              ensure your ability to restore your game progress and analyze your use
              of our Games.
            </p>
            <p>
              In case the applicable data protection legislation changes to require
              personal data storage for a longer or shorter period, we will store your
              personal data in accordance with these new legal requirements.
            </p>
            <p>
              Please note that upon expiration of the specified period, your data will
              be deleted, and you will not be able to restore your game progress.
            </p>
            <p>
              As for the storage of Cookies on the Website, their storage is limited
              to the period of your session on the Website. However, there are several
              types of Cookies that can be stored for up to 1 year. These include
              Cookies that store information about the first visit of the Website and
              User&apos;s Device.
            </p>
            <h2>YOUR RIGHTS</h2>
            <text>
              We respect your rights and guarantee the exercise of the following
              rights:
              <ul>
                <li>
                  you have a right to request access to your personal data (request
                  the upload of a copy of your personal data), and also have the right
                  to correct or delete it;
                </li>
                <li>
                  you have a right to prohibit processing or object to processing of
                  your personal data;
                </li>
                <li>
                  you have a right to request information about our procedures of
                  processing your data;
                </li>
                <li>
                  you have a right to file a complaint against us with a supervisory
                  authority of the country of your residency.
                </li>
              </ul>
              <p>
                You may also exercise any rights provided for by the applicable data
                protection legislation. If you want to exercise one of the rights
                listed, please, contact us
                via <a href="mailto:support@carx-europe.com"> support@carx-europe.com</a>.
                Please, note that in order to avoid errors and abuse, we will need to
                identify the sender of the request, so we may request additional
                information from you, including User information or IDs.
              </p>
              <p>
                Please, note that if you request erasure of your personal data, we will
                fulfill such a requirement within 30 days, and your game progress on all
                Devices will be lost. In addition, if your request is submitted to
                prohibit us from processing, or object to the processing of your
                personal data, we will be forced to terminate or restrict your access to
                the Services, since their functionality depends on the ability to
                process personal data.
              </p>
              <h2>PROTECTION OF PERSONAL DATA</h2>
              <p>The security of your personal data is important to us.</p>
              <p>
                We observe generally accepted standards to protect your information. In
                particular, we have taken at least the following measures:
                <br />
                1) we use TLS 1.2 encryption or even more secure for all network
                connections through which data is transmitted;
                <br />
                2) Testing of systems for vulnerabilities and security issues at least
                once every 12 months;
                <br />
                3) Access to confidential data is protected, for example, through
                passwords or access tokens;
                <br />
                4) The incident response processes are tested at least once every 12
                months;
                <br />
                5) A system for maintaining accounts has been introduced in CarX
                Technologies;
                <br />
                6) An automated system has been introduced to monitor logs and other
                security events, as well as to generate warnings about abnormal or
                security-related events.
              </p>
              <p>
                Our employees and third parties are obliged to keep personal data
                confidential when accessing your data.
              </p>
              <p>
                We are constantly improving our data security systems and doing
                everything in our capacity to prevent its leakage. In case such a leak
                occurs, we undertake to notify Users and the regulatory authority about
                the incident as quickly as possible, as well as to make every effort to
                minimize negative consequences.
              </p>
              <p>
                We also draw your attention to the fact that your data in accordance with section “Is personal data
                transferred to third parties?” is transmitted by us to our partners who are located in EU and USA. We
                also assure you that your data is safe, and our partners in the USA adhere to high standards of data
                protection, as do we. We undertake to transfer your personal data to the United States only by using all
                necessary security mechanisms to protect your data, including data encryption.
              </p>
              <h2>INFORMATION FOR EU RESIDENTS</h2>
              <p>
                You can exercise all the rights provided for in section “What rights in
                relation to personal data do you have?” in accordance with GDPR.
              </p>
              <p>
                Please, note that in order to ensure your rights regarding collection,
                storage and processing of personal data, you have a right to file a
                complaint with the authority at your place of residence. The list of
                responsible authorities can be found at: <a
                  href="https://edpb.europa.eu/about-edpb/about-edpb/members_en"
                  target="_blank"
                  rel="noreferrer"
                >
                  https://edpb.europa.eu/about-edpb/about-edpb/members_en
                </a> .
              </p>
              <p>
                Please, note that in accordance with section “Is personal data
                transferred to third parties? your data may be transferred to our
                partners who are located in the Russian Federation and USA. These
                countries may not provide the same level of data protection as the
                legislation of your country of residence.
              </p>
              <p>
                We undertake to store your personal data only in those jurisdictions
                that use the necessary security mechanisms to protect your personal
                data, including various data encryption methods. With each of the
                partners, we accept obligations under the standard contractual clauses
                adopted by the EU Commission, which give us a legal basis for such
                transfer.
              </p>
              <p>
                Although we and third-party partners take all reasonable and necessary
                measures to protect your personal data from unauthorized access by third
                parties, we must inform you about the potential risks of such storage of
                your personal data within jurisdictions outside of the EEA in accordance
                with Article 49 (1) (a) GDPR.
              </p>
              <p>Among such potential risks are:</p>
              <ol>
                <li>
                  existence of rules and regulations on processing of personal data in
                  such jurisdictions other than GDPR. Despite this, we provide personal
                  data subjects with the level of guarantees provided to personal data
                  subjects by GDPR.
                </li>
                <li>
                  Obtaining access to your personal data by government agencies of the
                  Russian Federation or the United States, or other special government
                  services. However, such access can be obtained by these authorities
                  only in accordance with the current legislation of the relevant
                  country and if there are legal grounds for obtaining access. We
                  undertake not to disclose data in absence of a legitimate reason and
                  to do so only to the minimum extent necessary for this.
                </li>
                <li>
                  Attempts to illegally access personal data. We take all the necessary
                  measures to prevent such attempts and prevent unauthorized access to
                  your personal data.
                </li>
              </ol>
              <p>
                By agreeing to processing of your personal data in accordance with this
                Privacy Policy, you give us explicit consent to transfer your personal
                data to jurisdictions outside the EEA, despite all possible risks of
                such transfer.
              </p>
              <h2>INFORMATION FOR CALIFORNIA RESIDENTS (USA)</h2>
              <p>
                The California Consumer Privacy Act (hereinafter “<b>CCPA</b>”) applies
                to processing of personal data in relation to customers who are
                residents of California.
              </p>
              <p>
                We take measures to collect, use and disclose such data solely for the
                purpose of providing Services to you, or for other purposes provided for
                by the CCPA.
              </p>
              <p>
                <b>
                  “Do not sell my personal data”. We guarantee that we do not sell
                  Users&apos; personal data directly for a monetary reward.
                </b>
                <br />
                At the same time, we understand that CCPA has a very broad understanding
                of the term “data sale”. Therefore, in order to avoid discrepancies in
                interpretation, we want to confirm that some of our Games contain
                advertising, as described in section “Is personal data transferred to
                third parties?”. Besides, we do not monetize the transfer of personal
                data to third parties in any way.
              </p>
            </text>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy;