import React from "react";
import './TopSection.scss';

const TopSection = (props) => {
    return (

        <div className="wrapper-top">
            <div className="wrapper-h1"><h1>We develop<br/> racing games</h1></div>
            <div className="header-img"><img src="header.png" alt=""/></div>
        </div>
    )
}

export default TopSection;
