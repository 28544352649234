// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderMinimal_header__UUaBC {
  position: fixed;
  width: 100%;
  height: 100px;
  display: block;
  left: 0;
  top: 0;
  z-index: 90;
  border-bottom: 1px solid var(--bg-color-purple);
  background: rgba(34, 32, 39, 0.4);
  backdrop-filter: blur(26px);
}
.HeaderMinimal_header__UUaBC a {
  display: flex;
  justify-content: center;
}

.HeaderMinimal_header_logo__cUs6N {
  width: 220px;
  margin-top: 36px;
}
.HeaderMinimal_header_logo__cUs6N:hover {
  cursor: pointer;
}

.HeaderMinimal_clearTop__eXI82 {
  background-color: transparent;
  height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/HeaderMinimal/HeaderMinimal.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,aAAA;EACA,cAAA;EACA,OAAA;EACA,MAAA;EACA,WAAA;EACA,+CAAA;EACA,iCAAA;EAEA,2BAAA;AACF;AACE;EACE,aAAA;EACA,uBAAA;AACJ;;AAGA;EACE,YAAA;EACA,gBAAA;AAAF;AAEE;EACE,eAAA;AAAJ;;AAIA;EACE,6BAAA;EACA,aAAA;AADF","sourcesContent":[".header {\n  position: fixed;\n  width: 100%;\n  height: 100px;\n  display: block;\n  left: 0;\n  top: 0;\n  z-index: 90;\n  border-bottom: 1px solid var(--bg-color-purple);\n  background: rgb(34 32 39 / 40%);\n  -webkit-backdrop-filter: blur(26px);\n  backdrop-filter: blur(26px);\n\n  a {\n    display: flex;\n    justify-content: center;\n  }\n}\n\n.header_logo {\n  width: 220px;\n  margin-top: 36px;\n\n  &:hover {\n    cursor: pointer;\n  }\n}\n\n.clearTop {\n  background-color: transparent;\n  height: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `HeaderMinimal_header__UUaBC`,
	"header_logo": `HeaderMinimal_header_logo__cUs6N`,
	"clearTop": `HeaderMinimal_clearTop__eXI82`
};
export default ___CSS_LOADER_EXPORT___;
