import React from "react";
import './StatusForm.scss';


const StatusForm = ({show}) => {
    return (
        <div style={{display:show ? 'block' : 'none' }}>
            <div className="wrapper-status">
                <div className="green_circle"></div>
                <p>Thanks for submitting your <br/>application</p>
            </div>

        </div>
    )
};

export default StatusForm