import { AboutProps } from './About.props';
import bgVideoWebm from '../../videos/street-bg.webm';
import bgVideoMP4 from '../../videos/street-bg.mp4';
import classes from './About.module.scss';

export const About = (props: AboutProps) => {

    return (
        <section className={classes.about}>
            <video className={classes.background_video} autoPlay muted loop>
                <source src={bgVideoWebm} type="video/webm" />
                <source src={bgVideoMP4} type="video/mp4" />
            </video>
            <div className={classes.about_titleWripper}>
                <h1 className={classes.about_title}>Driving the Future of Racing Games</h1>
            </div>
            <p className={`${classes.about_text} ${classes.text_shine}`}>Welcome to CarX Technologies Europe, one of the global leaders in racing game development,
                renowned for creating the popular CarX racing franchise</p>
        </section>
    );
};