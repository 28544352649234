import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import './NarrativeDesign.scss';
import HeaderMinimal from "../../components/HeaderMinimal";
import Footer from "../../components/Footer";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

const NarrativeDesign = () => {
    const array = [
        {id: '1', vacancy: 'Narrative Designer', date:'10 January 2023', exp: '8 years', archive:'23 March 2023', active: true  },
    ];
    const hasActive = array.filter(item => item.active).length > 0;
    const [vacArray, setVacArray] = useState(array)

    const handleOpenClose = (value) => {
        const item = vacArray[0];
        setVacArray([{...item, ...{active: value}}])
        //console.log(value)
    }

    return (
        <>
        <HeaderMinimal/>
            <div className="vacancy-wrapper">
                <div className={'back' + (hasActive ? "" : " hidden")}>
                    <Link to="/">← BACK</Link>
                </div>
               {/* <div className="stateVacancy">
                    <p className={vacArray[0].active ? "active" : ""} onClick={() => handleOpenClose(true)}>Открыта</p>
                    <p className={!vacArray[0].active ? "active" : ""} onClick={() => handleOpenClose(false)}>Закрыта</p>
                </div>*/}
                <p className="expectations">«We expect passion for great gaming experience. You know how to put the player first, and know
                    <br/>how to deliver a story that blends with gameplay»</p>

                {vacArray.map(item =>(
                        <div key={item.id}>
                            {item.active && (
                            <div className="vacancy-elements" style={{color: 'white'}}>
                                <div className="h1-block">
                                    <p>{item.vacancy}</p>
                                    <span>Vacancy published on {item.date}</span><br/>
                                    <span>Experience from {item.exp}</span>
                                </div>

                                <div className="respons">
                                <div className="response_h2">RESPONSIBILITIES</div>
                                    <ul>
                                        <li><span>Create vibrant open-world gaming environment.</span></li>
                                        <li><span>Create and empower player choice adhering to the creative vision of the game.</span></li>
                                        <li><span>Collaborate with the team to plan the game’s setting, characters, and storylines.</span></li>
                                        <li><span>Design and write pitches for story chapters and game events.</span></li>
                                        <li><span>Develop dynamic, diverse characters and stories for our diverse player base.</span></li>
                                        <li><span>Write, review, and edit game content and dialogs.</span></li>
                                    </ul>
                                </div>
                                <div className="respons">
                                    <div className="response_h2">QUALIFICATIONS</div>
                                    <ul>
                                        <li><span>8+ years experience working in games, either as a writer or narrative designer.</span></li>
                                        <li><span>Experienced in narrative driven content.</span></li>
                                        <li><span>Great attention to details.</span></li>
                                        <li><span>Some experience with the Unity Engine is preferred, but not required.</span></li>

                                    </ul>
                                </div>
                                <div className="respons">
                                    <div className="response_h2">WHAT WE OFFER</div>
                                    <ul>
                                        <li><span>Flexible Working Hours.</span></li>
                                        <li><span>PTO Days for Wellness and Sickness.</span></li>
                                        <li><span>Remote Work Flexibility.</span></li>
                                        <li><span>Training and Skill Development Opportunities.</span></li>
                                    </ul>
                                </div>
                                <div className="respons">
                                    <div className="response_h2">CONTACT US</div>
                                    <ul>
                                        <span>info@carx-europe.com</span>
                                    </ul>
                                </div>


                                {/*<div className="respons">
                                    <div className="response_h2">APPLICATION FORM</div>
                                    <Form  className="contact-form vacancy">
                                        <Form.Group>
                                            <Form.Control type="text" placeholder="Name"/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Control type="text" placeholder="Email"/>
                                        </Form.Group>
                                        <Form.Group>
                                        <textarea className="form-control"
                                                  name="message"
                                                  rows="1"
                                                  placeholder="Message"
                                                  autoComplete="on"
                                                  id="messages"
                                                  style={{resize: 'none'}}
                                        ></textarea>
                                        </Form.Group>
                                        <div className="auto_block">
                                            <InputGroup>
                                                <InputGroup.Checkbox aria-label="" required />
                                                <p>I confirm I am 18 years of age or older</p>
                                            </InputGroup>
                                            <InputGroup>
                                                <InputGroup.Checkbox aria-label="" required />
                                                <p>I have read and agree to the <Link >Privacy Policy</Link></p>
                                            </InputGroup>
                                        </div>

                                        <div className="btn-wrapper">
                                            <button className="btn-contact" variant="secondary" type="submit" form="recovery">Submit Application</button>
                                        </div>
                                    </Form>
                                </div>*/}

                            </div>
                        )}
                            {!item.active && (
                                <div className="vacancy-elements">
                                    <div className="h1-notActive">
                                        <p>{item.vacancy}</p>
                                        <span>Archived since {item.archive}</span><br/>
                                    </div>
                                </div>
                            )}
                        </div>
                ))}
            </div>
            <Footer/>
        </>
    )
}

export default NarrativeDesign;