import React from "react";
import {Link} from "react-router-dom";
import './Job.scss';

const Job = (props) => {
    const array = [
        {id: '2', vacancy: 'Localization Manager', exp: 'Experience from 3 years',link:'2024/localization-manager', active: true},
        {id: '1', vacancy: 'Narrative Design', exp: 'Experience from 8 years',link:'2023/narrative-design', active: true},
        
        /*{id: '2', vacancy: '3D Modeler', exp: 'Experience from 6 years', active: false},
        {id: '3', vacancy: 'Project manager', exp: 'Experience from 4 years', active: false}*/
    ];
    const hasActive = array.filter(item => item.active).length > 0;
    return (
        <>
            <div className="job-wrapper" id="job">
                <div className="job_h1">
                    <span>JOB OPPORTUNITIES</span>
                    <p>First and foremost we expect from the candidate a passion for racing games</p>
                </div>
                    <div className="container">
                        {array.map(item => (
                        <Link key={item.id} to={item.link} target="_blank" className={`job-list-elements ${item.active ? "" : "notActive"}`}>
                            <div>
                                <span className="elements_vacancy">{item.vacancy}</span>
                                <p className="elements_experience">{item.exp}</p>
                            </div>
                            <div className="read-element">
                                <span>Read more</span>
                                <span className="arrow right"></span>
                            </div>
    
                        </Link>
                        ))}
                    </div>
            </div>

        </>
    )
}

export default Job;