import { useEffect, useState } from "react";
import { CareerItem } from './CareerItem';
import { CareersProps } from './Careers.props';
import classes from './Careers.module.scss';

export const Careers = (props: CareersProps) => {

    const [careers, setCareers] = useState<any[]>([]);

    useEffect(() => {
        const fetchCareers = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/careers`);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const res = await response.json();
                setCareers(res.data);
            } catch (error) {
                console.error('Fetch error: ', error);

            }
        };

        fetchCareers();
    }, []);

    return (
        <section className={classes.careers} id="careers">
            <div className={classes.careers_wrapper}>
                <div className={`${classes.container} ${classes.careers_containerAbout} `}>
                    <h3 className={classes.careers_aboutTitle}>CAREERS</h3>
                    <p className={classes.careers_aboutText}>Mobile and console games with huge audience of racing
                        and drifting fans Realistic physics and car behavior on smartphone</p>
                    <h3 className={classes.careers_aboutTitleSecond}>MOBILE & CONSOLE</h3>
                    <p className={classes.careers_aboutTextSecond}>Aspiring Irish game developers can stay in Ireland and
                        get the experience of working
                        on world-class games for all platforms</p>
                </div>
                <div className={classes.careers_containerJob}>
                    <h3 className={classes.careers_containerJobTitle}>JOB OPPORTUNITIES</h3>
                    <p className={classes.careers_containerJobText}>First and foremost we expect from the candidate a passion for racing games</p>
                    <div className={classes.careers_jobWrapper}>
                        {careers.map((career) => (
                            <CareerItem
                                key={career.id}
                                id={career.id}
                                documentId={career.documentId}
                                title={career.title}
                                experience={career.experience}
                                subtitle={career.subtitle}
                                about={career.about}
                                contact={career.contact}
                                extra={career.extra}
                                offer={career.offer}
                                published={career.published}
                                qualifications={career.qualifications}
                                responsibilities={career.responsibilities}
                            />
                        ))
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};