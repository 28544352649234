import React from "react";
import './Mission.scss';

const Mission = (props) => {

    return (
        <div id="mission">
            <span className="mission" >Our mission is to develop the best racing games<br/> and deliver unprecedented experience<br/> to our gamers</span>
        </div>
    )
}

export default Mission;