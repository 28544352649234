import Place1 from '../images/place1.webp';
import Place2 from '../images/place2.webp';
import Game1 from '../images/games/dr3.webp';
import Game2 from '../images/games/streetPC.webp';
import Game4 from '../images/games/street.webp';
import Game3 from '../images/games/dro.webp';
import Game5 from '../images/games/dr2.webp';
import Game6 from '../images/games/rally.webp';
import Strength1 from '../images/strenghts1.webp';
import Strength2 from '../images/strenghts2.webp';
import Value1 from '../images/icons/values1.svg';
import Value2 from '../images/icons/values2.svg';
import Value3 from '../images/icons/values3.svg';
import Value4 from '../images/icons/values4.svg';
import Value5 from '../images/icons/values5.svg';
import imgLink1 from '../images/social_icons/apple.svg';
import imgLink2 from '../images/social_icons/google-playstore.svg';
import imgLink3 from '../images/social_icons/steam.svg';
import imgLink0 from '../images/social_icons/carx-store.svg';

export const POLICY_URL = "../uploads/userfiles/Privacy_Policy_ENG.pdf";
export const EULA_URL = "../uploads/userfiles/EULA_ENG.pdf";

export const GamesList = [
    {
        "id": 1,
        "img": Game1,
        "link0": "https://carx-store.com/en/dr3",
        "link1": "https://apps.apple.com/us/app/carx-drift-racing-3/id1569386864",
        "link2": "https://play.google.com/store/apps/details?id=com.carxtech.carxdr3",
        "imgLink0": imgLink0,
        "imgLink1": imgLink1,
        "imgLink2": imgLink2
    },
    {
        "id": 2,
        "img": Game2,
        "link0": "https://carx-store.com/en/streetpc",
        "link1": "https://store.steampowered.com/app/1114150/CarX_Street/",
        "imgLink0": imgLink0,
        "imgLink3": imgLink3,

    },
    {
        "id": 3,
        "img": Game3,
        "link0": "https://carx-store.com/en/dro",
        "link1": "https://store.steampowered.com/app/635260/CarX_Drift_Racing_Online/",
        "imgLink0": imgLink0,
        "imgLink3": imgLink3
    },
    {
        "id": 4,
        "img": Game4,
        "link0": "https://carx-store.com/en/street",
        "link1": "https://apps.apple.com/ru/app/carx-street/id1458863319",
        "link2": "https://play.google.com/store/apps/details?id=com.carxtech.sr&hl=en_US",
        "imgLink0": imgLink0,
        "imgLink1": imgLink1,
        "imgLink2": imgLink2
    },
    {
        "id": 5,
        "img": Game5,
        "link0": "https://carx-store.com/en/dr2",
        "link1": "https://apps.apple.com/ru/app/carx-drift-racing-2/id1198510863",
        "imgLink0": imgLink0,
        "imgLink1": imgLink1
    },
    {
        "id": 6,
        "img": Game6,
        "link1": "https://apps.apple.com/us/app/carx-rally/id1332286428",
        "link2": "https://play.google.com/store/apps/details?id=com.carxtech.rally",
        "imgLink1": imgLink1,
        "imgLink2": imgLink2
    }
];

export const PlaceList = [
    {
        "id": 1,
        "img": Place1,
    },
    {
        "id": 2,
        "img": Place2,
    }
];

export const StrengthsList = [
    {
        "id": 1,
        "title": "Professional Development Team",
        "text": "A team of experienced professionals with expertise in game design, graphics, and programming, ensuring high-quality games",
        "img": "#",
    },
    {
        "id": 2,
        "title": "Proprietary Technologies",
        "text": "A proprietary driving physics engine, 'CarX Technology'©, has been developed to provide a realistic and immersive driving experience",
        "img": Strength1,
    },
    {
        "id": 3,
        "title": "Motorsport Collaboration and Expertise",
        "text": "Working closely with a professional racing team and motorsport experts to bring authentic racing experience and adopt the latest advancements and best practices from the racing world",
        "img": "#",
    },
    {
        "id": 4,
        "title": "Multi-Platform Development",
        "text": "We develop games for a wide range of platforms, including mobile devices, PC, consoles (PlayStation, Xbox, Nintendo), and VR",
        "img": Strength2,
    },
    {
        "id": 5,
        "title": "Strong Position in the Mobile Market",
        "text": "Our mobile games have achieved millions of downloads and consistently rank among the top in the racing genre",
        "img": "#",
    }
];

export const ValuesList = [
    {
        "id": 1,
        "img": Value1,
        "title": "Player - Centric",
        "text": "A team of experienced professionals with expertise in game design, graphics, and programming, ensuring high-quality games"

    },
    {
        "id": 2,
        "img": Value2,
        "title": "Encouraging Healthy Gameplay",
        "text": "A team of experienced professionals with expertise in game design, graphics, and programming, ensuring high-quality games"

    },
    {
        "id": 3,
        "img": Value3,
        "title": "Synergy and Teamwork",
        "text": "A team of experienced professionals with expertise in game design, graphics, and programming, ensuring high-quality games"

    },
    {
        "id": 4,
        "img": Value4,
        "title": "Passion for Growth",
        "text": "A team of experienced professionals with expertise in game design, graphics, and programming, ensuring high-quality games"
    },
    {
        "id": 5,
        "img": Value5,
        "title": "Focus on Quality",
        "text": "A team of experienced professionals with expertise in game design, graphics, and programming, ensuring high-quality games"
    }
];