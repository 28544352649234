import { FooterProps } from './Footer.props';
import logo from '../../images/logo/logo.svg';
import classes from './Footer.module.scss';

export const Footer = (props: FooterProps) => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className={classes.footer}>
            <img
                className={classes.footer_img}
                src={logo}
                alt='Логотип'
            />
            <div className={classes.footer_infoContainer}>
                <p className={classes.footer_info}>CarX Technologies EU Ltd.</p>
                <p className={classes.footer_info}>© 2022 — {currentYear}</p>
            </div>
            <div className={classes.footer_policyContainer}>
                <a className={classes.footer_policy} href='/carx-technologies-privacy-policy' target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                <a className={classes.footer_policy} href='/carx-technologies-eula' target="_blank" rel="noopener noreferrer">EULA</a>
            </div>
        </footer>
    );
};