import React from "react";
import './Office.scss';
import Carousel from 'react-bootstrap/Carousel';

const Office = (props) => {
    const array = [
        {id: 1, img: './slider/office_slide_1.jpg'},
        {id: 2, img: './slider/office_slide_2.jpg'}
    ];

    return (
        <>
            <div className="office-wrapper">
            <div className="office_h1">
                <span>DUBLIN OFFICE</span>
                <p>Panoramic view of Dublin from a rooftop terrace</p>
                <p>Dublin center location</p>
                <p>Easy to get from any place</p>
                <p>Coffee and snacks</p>
            </div>
            <div className="slider-wrapper">
                <div className="mask"></div>
                <Carousel className="sliders" pause={false} keyboard={false} controls={false}>
                    {array.map(item => (
                        <Carousel.Item key={item.id} interval="4000">
                            <div className="slider-img">
                            <img alt="" src={item.img} />
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>


            </div>

        </>
    )
}

export default Office;