import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';

import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NarrativeDesign from './pages/2023/NarrativeDesign';
import LocalizationManager from './pages/2024/LocalizationManager';
import Cookie from './components/cookie/Cookie';

import './AdaptiveStyle.scss'; // Не менять строку!
import PageRoot from "./pages/PageRoot";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
                    <PageRoot>
                        <Routes >
                            <Route path={"/"} exact={true} element={<App />}/>
                            <Route path={"2023/narrative-design"} element={<NarrativeDesign />}/>
                            <Route path={'2024/localization-manager'} element={<LocalizationManager/>} />
                            <Route path={"cookie"} element={<Cookie />}/>
                        </Routes>
                    </PageRoot>
        </BrowserRouter>
    </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
