// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/bg/main_bg.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wrapper_wrapper__GC3yJ {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: var(--bg-color-black-main);
  color: var(--text-color-white);
  transition: all var(--transition-duration) var(--transition-timing-fn);
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-user-select: none;
  user-select: none;
  max-width: 2560px;
  padding: 0 140px;
}
@media (max-width: 1024px) {
  .Wrapper_wrapper__GC3yJ {
    padding: 0 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Wrapper/Wrapper.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,4CAAA;EACA,8BAAA;EACA,sEAAA;EACA,yDAAA;EACA,4BAAA;EACA,sBAAA;EACA,yBAAA;EAGA,iBAAA;EAEA,iBAAA;EACA,gBAAA;AAAF;AAEE;EAvBF;IAwBI,eAAA;EACF;AACF","sourcesContent":[".wrapper {\n  min-height: 100vh;\n  width: 100%;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  justify-items: center;\n  background-color: var(--bg-color-black-main);\n  color: var(--text-color-white);\n  transition: all var(--transition-duration) var(--transition-timing-fn);\n  background-image: url(\"../../images/bg/main_bg.webp\");\n  background-repeat: no-repeat;\n  background-size: cover;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n\n  max-width: 2560px;\n  padding: 0 140px;\n\n  @media (max-width: 1024px) {\n    padding: 0 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Wrapper_wrapper__GC3yJ`
};
export default ___CSS_LOADER_EXPORT___;
