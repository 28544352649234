import React, { useState} from "react";
import './Header.scss';

import { HashLink as Link } from 'react-router-hash-link';




const Header = () => {

    const scrollOffset = (e, offset) => {
        const positionY = e.offsetTop - offset;
        window.scroll({
            top: positionY
        });
    };
    const [show, setShow] = useState(false);
    const handleHide = () => {
        setShow(false);
    }
    const offsetDesktop = '300';
    const offsetMobile = '150';

    return (
    <>
        <header>
            <Link to='/' onClick={() => setTimeout(() => window.scroll(0,0), 200)}>
                <div className="logo"></div>
            </Link>

            <div className="menu-hamburger">
                <input id="menu__toggle" type="checkbox" checked={show} onChange={e => setShow(e.target.checked)}/>
                <label className="menu__btn" htmlFor="menu__toggle">
                    <span></span>
                </label>
                <ul className="menu__box">
                    <li><Link to='/#mission' className="menu__item" scroll={e => scrollOffset(e, offsetMobile)} onClick={handleHide} >About us</Link></li>
                    <li><Link to='/#ourgames' className="menu__item" scroll={e => scrollOffset(e, offsetMobile)} onClick={handleHide} >Games</Link></li>
                    <li><Link to='/#job' className="menu__item" scroll={e => scrollOffset(e, offsetMobile)} onClick={handleHide} >Careers</Link></li>
                    <li><Link to='/#contact' className="menu__item" scroll={e => scrollOffset(e, offsetMobile)} onClick={handleHide} >Contact us</Link></li>
                </ul>
            </div>

            <div className="menu">
                <ul>
                    <li><Link  to='/#mission' scroll={e => scrollOffset(e, offsetDesktop)}>About us</Link></li>
                    <li><Link  to='/#ourgames' scroll={e => scrollOffset(e, offsetDesktop)}>Games</Link></li>
                    <li><Link  to='/#job' scroll={e => scrollOffset(e, offsetDesktop)}>Careers</Link></li>
                    <li><Link  to='/#contact' scroll={e => scrollOffset(e, offsetDesktop)}>Contact us</Link></li>
                </ul>
            </div>
        </header>
    <div className="clearTop"></div>
    </>


    )
}

export default Header;
