import React, {useState} from "react";
import './Ourgames.scss';
import { NavLink } from 'react-router-dom';
import TabsPlatform from "./TabsPlatform";


const Ourgames = (timer={}) => {


    /*positionX();
    const positionX = () => {
        let positionx = 1;
	timer = setInterval(function() {
        positionx += Math.floor(1*12/10);
		$(".kie").css('background-position-x', -positionx + 'px');
		$(".ki").css('background-position-x', positionx + 'px');

	}, 15);

	}*/



    return (
        <>
        <div className="section_h1" id="ourgames">
            <span>OUR GAMES</span>
            <p>Mobile and console games with huge audience of racing and drifting fans<br/> Realistic physics and car behavior on smartphone</p>
        </div>

        <TabsPlatform />




        </>
    )
}

export default Ourgames;