// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Contact_contact__Ma-0s {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 145px;
  padding-bottom: 240px;
}

.Contact_contact_title__2o4xZ {
  color: var(--text-color-white);
  font: var(--font-size-100)/1.5 Onest-Bold;
  letter-spacing: -0.05em;
  text-align: center;
}

.Contact_contact_email__yrQy2 {
  color: var(--text-color-yellow);
  font: var(--font-size-50)/1.5 Onest-Bold;
  letter-spacing: -0.05em;
  text-align: center;
  text-decoration: underline;
}
.Contact_contact_email__yrQy2:hover {
  color: var(--text-color-blue);
}`, "",{"version":3,"sources":["webpack://./src/components/Contact/Contact.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,iBAAA;EACA,qBAAA;AACF;;AAEA;EACE,8BAAA;EACA,yCAAA;EACA,uBAAA;EACA,kBAAA;AACF;;AAEA;EACE,+BAAA;EACA,wCAAA;EACA,uBAAA;EACA,kBAAA;EACA,0BAAA;AACF;AACE;EACE,6BAAA;AACJ","sourcesContent":[".contact {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n  margin-top: 145px;\n  padding-bottom: 240px;\n}\n\n.contact_title {\n  color: var(--text-color-white);\n  font: var(--font-size-100)/1.5 Onest-Bold;\n  letter-spacing: -0.05em;\n  text-align: center;\n}\n\n.contact_email {\n  color: var(--text-color-yellow);\n  font: var(--font-size-50)/1.5 Onest-Bold;\n  letter-spacing: -0.05em;\n  text-align: center;\n  text-decoration: underline;\n\n  &:hover {\n    color: var(--text-color-blue);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contact": `Contact_contact__Ma-0s`,
	"contact_title": `Contact_contact_title__2o4xZ`,
	"contact_email": `Contact_contact_email__yrQy2`
};
export default ___CSS_LOADER_EXPORT___;
