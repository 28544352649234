import React from "react";
import './Footer.scss';
import {GetCurrentYear} from "./GetCurrentYear";


const Footer = (props) => {
    return (
        <footer>
            <div className="copyright">

                <span>CarX Technologies EU Ltd.</span><br/>
                <span>© 2022 — <GetCurrentYear/></span>
            </div>
        </footer>
    )
}

export default Footer;