import { Link } from "react-router-dom";
import { CareerItemProps } from './CareerItem.props';
import classes from './CareerItem.module.scss';

export const CareerItem = ({
    id,
    documentId,
    title,
    experience
}: CareerItemProps) => {

    return (
        // <div className={`${classes.container} ${classes.careerItem}`}>
        //     <div className={classes.careerItemWrapper}>
        //         <h4 className={classes.careerItemTitle}>{name}</h4>
        //         <p className={classes.careerItemText}>{experience}</p>
        //     </div>
        //     <Link className={classes.careerBtn} to={`/careers/${documentId}`}>Read more</Link>
        // </div>
        <Link className={`${classes.container} ${classes.careerItem}`} to={`/careers/${documentId}`}>
            <div className={classes.careerItemWrapper}>
                <h4 className={classes.careerItemTitle}>{title}</h4>
                <p className={classes.careerItemText}>{experience}</p>
            </div>
            <button className={classes.careerBtn}>Read more</button>
        </Link>
    );
};