import React from "react";
import './Separator.scss';

const Separator = (props) => {
    return (

        <span className="separator">
            <span className="step"></span>
        </span>

    )
}

export default Separator;
