import { MissionProps } from './Mission.props';
import Car from "../../images/car.webp"
import classes from './Mission.module.scss';

export const Mission = (props: MissionProps) => {

    return (
        <section className={classes.mission} id="about">
            {/* <img className={classes.mission_img} src={Car} alt="auto" /> */}
            <div className={classes.mission_containerText}>
                <h2 className={`${classes.mission_title} ${classes.text_shine}`}>Our mission</h2>
                <p className={classes.mission_textFirst}>At CarX Technologies, we aim to create the most realistic and immersive racing games by pushing the boundaries of driving physics and simulation technology. We put great effort into making cars in our games behave like real ones, with accurate movements, responsive controls, and lifelike driving mechanics
                </p>
                <p className={`${classes.mission_textSecond} ${classes.text_shine}`}><span className={`${classes.mission_textSecondImportant}${classes.text_shine}`}>Our mission is to develop the best racing games </span></p>
                <p className={`${classes.mission_textSecond} ${classes.text_shine}`}>and deliver unprecedented experience to our players</p>
            </div>
        </section >
    );
};