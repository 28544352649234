import { SayakaShimodaProps } from './SayakaShimoda.props';
import Sayaka from '../../images/sayaka.webp';
import SayakaBG from '../../images/bg/bg_sayaka.webp';
import classes from './SayakaShimoda.module.scss';

export const SayakaShimoda = (props: SayakaShimodaProps) => {

    return (
        <section className={classes.sayaka}>
            <img className={classes.sayaka_imgBG} src={SayakaBG} alt='Sayaka Shimoda' />
            <div className={classes.sayaka_titleWrapper}>
                <h2 className={classes.sayaka_title}>Sayaka <span className={classes.sayaka_titleSpan}>Shimoda</span></h2>
            </div>
            <div className={classes.sayaka_container}>
                <img className={classes.sayaka_img} src={Sayaka} alt='Sayaka Shimoda' />
                <div className={classes.sayaka_textWrapper}>
                    <h4 className={classes.sayaka_textFirst}>We’re honored to partner with Sayaka Shimoda, one of Japan’s top drift pilots</h4>
                    <p className={classes.sayaka_textSecond}>As the official ambassador of CarX games in Japan, Sayaka brings her expertise and passion for racing to CarX games and community</p>
                </div>
            </div>
        </section>
    );
};