import { MainProps } from './Main.props';
import { Header } from "../Header";
import { About } from "../About";
import { Mission } from "../Mission";
import { WhoWeAre } from "../WhoWeAre";
import { WhereMeets } from "../Motosport";
import { SayakaShimoda } from "../SayakaShimoda";
import { Strengths } from "../Strengths";
import { Values } from "../Values";
import { Games } from "../Games";
import { Careers } from "../Careers";
import { Location } from "../Location";
import { ModalAccept } from "../ModalAccept";
import classes from './Main.module.scss';

export const Main = (props: MainProps) => {

    return (
        <main className={classes.main}>
            <Header />
            <About />
            <Mission />
            <WhoWeAre />
            <WhereMeets />
            <SayakaShimoda />
            <Strengths />
            <Values />
            {/* <Games /> */}
            <Careers />
            <Location />
            <ModalAccept />
        </main>
    );
};