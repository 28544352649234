// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("Onest-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("Onest-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("Onest-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("Onest-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("Onest-Medium.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("Onest-Medium.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("Onest-Light.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("Onest-Light.woff", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Onest-Bold';
  src:
    url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Onest-Regular';
  src:
    url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Onest-Medium';
  src:
    url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Onest-Light';
  src:
    url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('woff2'),
    url(${___CSS_LOADER_URL_REPLACEMENT_7___}) format('woff');
  font-weight: 300;
  font-style: light;
}`, "",{"version":3,"sources":["webpack://./src/vendor/fonts/fonts.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB;;0DAEuC;EACvC,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B;;0DAE0C;EAC1C,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,2BAA2B;EAC3B;;0DAEyC;EACzC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;EAC1B;;0DAEwC;EACxC,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":["@font-face {\n  font-family: 'Onest-Bold';\n  src:\n    url('Onest-Bold.woff2') format('woff2'),\n    url('Onest-Bold.woff') format('woff');\n  font-weight: bold;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Onest-Regular';\n  src:\n    url('Onest-Regular.woff2') format('woff2'),\n    url('Onest-Regular.woff') format('woff');\n  font-weight: normal;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Onest-Medium';\n  src:\n    url('Onest-Medium.woff2') format('woff2'),\n    url('Onest-Medium.woff') format('woff');\n  font-weight: 500;\n  font-style: normal;\n}\n\n@font-face {\n  font-family: 'Onest-Light';\n  src:\n    url('Onest-Light.woff2') format('woff2'),\n    url('Onest-Light.woff') format('woff');\n  font-weight: 300;\n  font-style: light;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
