// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
}

a {
  text-decoration: none;
}

ul,
li,
img,
div {
  border: none;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/vendor/styles/_reset.scss"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,6BAA6B;EAC7B,WAAW;AACb;;AAEA;EACE,qBAAqB;AACvB;;AAEA;;;;EAIE,YAAY;EACZ,aAAa;AACf","sourcesContent":["* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  border: none;\n  outline: none;\n  background-color: transparent;\n  width: 100%;\n}\n\na {\n  text-decoration: none;\n}\n\nul,\nli,\nimg,\ndiv {\n  border: none;\n  outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
