import React, {useState, useEffect} from "react";
import Form from "react-bootstrap/Form";
import './Contact.scss';
import StatusForm from "./StatusForm";
import InputGroup from "react-bootstrap/InputGroup";
import {Link} from "react-router-dom";



const Contact = ()  => {
    const [showMac, setShowMac] = useState(true);
    const handleSubmit = (e) => {
        e.preventDefault();
        setShowMac(false);
    }

    return (
        <>

            <div className="contact-wrapper">
                <div className="contact_h1">
                    <span>CONTACT US</span>
                    <p>info@carx-europe.com</p>
                </div>

                {/*<StatusForm show={!showMac} />
                <Form className={"contact-form" + (showMac ? ' block' : ' none')} onSubmit={handleSubmit} id="inForm">
                    <Form.Group><Form.Control required type="text" placeholder="Name*"/></Form.Group>
                    <Form.Group><Form.Control required type="text" placeholder="Email*"/></Form.Group>
                    <Form.Group>
                        <textarea className="form-control"
                                  name="message"
                                  rows="1"
                                  placeholder="Message*"
                                  autoComplete="on"
                                  id="messages"
                                  required
                                  style={{resize: 'none'}}
                        ></textarea>
                    </Form.Group>

                    <div className="auto_block">
                        <InputGroup>
                            <InputGroup.Checkbox aria-label="" required />
                            <span>I confirm I am 18 years of age or older</span>
                        </InputGroup>
                        <InputGroup>
                            <InputGroup.Checkbox aria-label="" required />
                            <span>I have read and agree to the </span><Link>Privacy Policy</Link>
                        </InputGroup>
                    </div>
                    <div className="btn-wrapper">
                        <button className="btn-contact" variant="secondary" type="submit" form="inForm" >Submit</button>
                    </div>
                </Form>*/}

            </div>

        </>
    )
}

export default Contact;