import React, {useState} from "react";
import './Cookie.scss';
import Modal from "react-bootstrap/Modal";
import {Link, NavLink} from 'react-router-dom';

import Notice from "./Notice";
import Settings from "./Settings";
import {Tab, Tabs} from "react-bootstrap";


const Cookie = () => {

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);

    }
    const handleShow = () => {
        setShow(true);
    }


    return (
        <>
            <div className="cookie_wrapper">
                <div className="cookie_text">
                    <span>By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.</span>
                </div>
                <div className="cookie_settings">
                   <span className="feature_element" onClick={() => handleShow()}>Cookies Settings</span>
                </div>
                <div className="cookie_button">
                    <button className="accept">Accept All Cookies</button>
                </div>
            </div>

            <Modal show={show} onHide={handleClose} className="privacy-wrapper">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <div className="header-privacy">
                        <Tabs
                            defaultActiveKey="settings"
                            className="cookie-menu"
                            transition={true}
                            justify={false}>
                            <Tab eventKey="settings" title="Cookie Settings">
                                <Settings/>
                            </Tab>
                            <Tab eventKey="notice" title="Cookie Notice">
                               <Notice/>
                            </Tab>
                        </Tabs>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Cookie;