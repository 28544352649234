import { InfoModalProps } from './InfoModal.props';
import classes from './InfoModal.module.scss';

export const InfoModal = ({ isOpen, onClose }: InfoModalProps) => {
  if (!isOpen) return null;

  return (
    <div className={classes.overlay}>
      <div className={classes.modal}>
        <h2 className={classes.title}>Error loading data</h2>
        <p className={classes.text}>Refresh page and try again later</p>
        <button type='button' className={classes.btn} onClick={onClose}>ОК</button>
      </div>
    </div>
  );
};