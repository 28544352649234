import { ValuesProps } from './Values.props';
import { ValuesList } from '../../utils/constants';
import classes from './Values.module.scss';

export const Values = (props: ValuesProps) => {

    return (
        <section className={classes.values}>
            <h2 className={classes.values_title}>OUR <span className={classes.values_titleSpan}>VALUES</span></h2>
            <div className={classes.values_container}>
                {ValuesList.map((item, index) => (
                    <li
                        key={item.id}
                        className={classes.values_card}>

                        <img src={item.img} alt='yellow icon' className={classes.values_cardImg} />
                        <h5
                            className={`${classes.values_cardTitle} ${classes.text_shine}`}
                        >
                            {item.title}
                        </h5>
                        <p className={classes.values_cardText}>{item.text}</p>
                    </li>
                ))}
            </div>
        </section>
    );
};