import { WrapperProps } from './Wrapper.props';
import { Main } from '../Main';
import classes from './Wrapper.module.scss';


export const Wrapper = (props: WrapperProps) => {

    return (
        <div className={classes.wrapper}>
            <Main />
        </div>
    );
}