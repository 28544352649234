// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App_app__GQ97N {
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  background-color: var(--bg-color-black-main);
  color: var(--text-color-white);
  transition: all var(--transition-duration) var(--transition-timing-fn);
  -webkit-user-select: none;
  user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/components/App/App.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,4CAAA;EACA,8BAAA;EACA,sEAAA;EACA,yBAAA;EAGA,iBAAA;AACF","sourcesContent":[".app {\n  min-height: 100vh;\n  width: 100%;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  justify-items: center;\n  background-color: var(--bg-color-black-main);\n  color: var(--text-color-white);\n  transition: all var(--transition-duration) var(--transition-timing-fn);\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app": `App_app__GQ97N`
};
export default ___CSS_LOADER_EXPORT___;
