import { HeaderMinimalProps } from './HeaderMinimal.props';
import logo from '../../images/logo/logo.svg';
import classes from '../HeaderMinimal/HeaderMinimal.module.scss';
import { Link } from 'react-router-dom';

export const HeaderMinimal = (props: HeaderMinimalProps) => {

  return (
    <>
      <header className={classes.header}>
        <Link to='/'>
          <img
            className={classes.header_logo}
            src={logo}
            alt='Logo CarX Tech-EU'
          />
        </Link>
      </header>
      <div className={classes.clearTop}></div>
    </>
  )
};